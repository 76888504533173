define('scientia-resourcebooker/pods/components/booking-request-form/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog', 'ember-can'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog, _emberCan) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], _emberCan.CanMixin, {
    tagName: 'form',
    classNames: ['bookingRequestForm-content'],
    classNameBindings: ['bookingTypeNameClass'],

    // Services
    store: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    recurrenceProcessor: _ember['default'].inject.service(),
    dateTimeHelpers: _ember['default'].inject.service(),
    calendarOptionsHelper: _ember['default'].inject.service(),

    bookingTypeNameClass: _ember['default'].computed('bookingType', function () {
      return ('form-' + this.get('bookingType.name')).toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }),

    isEditing: _ember['default'].computed.bool('model.id'),
    isNew: _ember['default'].computed.not('isEditing'),
    isSaving: false,

    maxBookingTitleTextLength: _ember['default'].computed.alias('systemSettings.options.maxBookingTitleTextLength'),
    maxBookingFormTextLength: _ember['default'].computed.alias('systemSettings.options.maxBookingFormTextLength'),
    enableDescriptionFormField: _ember['default'].computed.alias('systemSettings.options.enableDescriptionFormField'),
    useOldTimePicker: _ember['default'].computed.alias('systemSettings.options.bookingUseSplitTimePicker'),

    bookingDescription: _ember['default'].computed.readOnly('model.description'),
    shouldShowDescriptionField: _ember['default'].computed('isEditing', 'enableDescriptionFormField', 'model.hasDirtyAttributes', function () {

      if (this.get('enableDescriptionFormField')) return true;
      if (!this.get('model')) return false; // fallback when the model gets unloaded

      var changedAttrs = this.get('model').changedAttributes();
      var hasOriginalDescription = this.get('model.hasDirtyAttributes') && !_ember['default'].isEmpty(changedAttrs.description) && !_ember['default'].isEmpty(changedAttrs.description[0]) || !_ember['default'].isEmpty(this.get('model.description'));
      return this.get('isEditing') && hasOriginalDescription;
    }),

    resourcesSelected: [],

    contentFromTime: null,
    contentToTime: null,

    // BOBO
    configEnableBoBo: _ember['default'].computed.bool('systemSettings.options.enableBobo'),
    canBookOnBehalfOf: _ember['default'].computed.and('configEnableBoBo', 'bookingType.canBookOnBehalfOf', 'isNew'),

    // BASE
    configEnableBase: _ember['default'].computed.bool('systemSettings.options.enableBase'),
    canBookAsSomeoneElse: _ember['default'].computed.and('configEnableBase', 'bookingType.canBookAsSomeoneElse', 'isNew'),

    spanMultipleDays: false,
    notSpanMultipleDays: _ember['default'].computed.not('spanMultipleDays'),

    bookingMode: 1, // 1 for single booking, 2 for Recurring
    repeatBooking: _ember['default'].computed.equal('bookingMode', 2),

    resource: null,

    bookingForm: null,
    didValidate: false,

    updateOccurrencePattern: false,

    calendarOptions: _ember['default'].computed('calendarOptions', 'bookingType.id', function () {
      var bt = this.get('bookingType');
      if (bt.get('calendarOptions.useCustomCalendarOptions')) {
        return bt.get('calendarOptions');
      } else {
        return this.get('systemSettings').getCalendarOptions();
      }
    }),

    calendarDaysDisabled: _ember['default'].computed('calendarOptions.calendarShowWeekends', function () {
      var showWeekends = this.get('calendarOptions.calendarShowWeekends');
      var weekendDays = this.get('systemSettings').getSetting('weekendDays');
      return showWeekends ? null : weekendDays;
    }),

    // Used by oldTimePicker
    startHour: _ember['default'].computed('calendarOptions.calendarStartTime', function () {
      return this.get('dateTimeHelpers').getStartHourFromTime(this.get('calendarOptions.calendarStartTime'));
    }),

    // Used by oldTimePicker
    endHour: _ember['default'].computed('calendarOptions.calendarEndTime', function () {
      return this.get('dateTimeHelpers').getEndHourFromTime(this.get('calendarOptions.calendarEndTime'));
    }),

    // Used by oldTimepicker
    timePickerMinutes: _ember['default'].computed('calendarOptions.calendarBookingTimes', function () {
      var bookingPeriods = this.get('calendarOptions.calendarBookingTimes');
      var periodsAvailable = this.get('calendarOptionsHelper').parse(bookingPeriods);
      return periodsAvailable.map(function (period) {
        var v = period < 10 ? '0' + period : '' + period;
        return _ember['default'].Object.create({ value: v, label: v });
      });
    }),

    // Used by oldTimepicker
    timePickerMinutesForLastHour: _ember['default'].computed('calendarOptions.calendarBookingTimes', function () {
      var endTime = this.get('calendarOptions.calendarEndTime');
      var bookingPeriods = this.get('calendarOptions.calendarBookingTimes');
      var periodsAvailable = this.get('calendarOptionsHelper').parse(bookingPeriods);
      var minutes = this.get('dateTimeHelpers').getAvailablePeriodsForLastHour(endTime, periodsAvailable);
      return minutes.map(function (period) {
        var v = period < 10 ? '0' + period : '' + period;
        return _ember['default'].Object.create({ value: v, label: v });
      });
    }),

    isBookingTimesSpanningDays: _ember['default'].computed('model.startDateTime', 'model.endDateTime', function () {
      return !this.get('model.startDateTime').isSame(this.get('model.endDateTime'), 'day');
    }),

    customFieldsDescriptionLength: (function () {
      return this.get('model.description.length') || '0';
    }).property('model.description.length'),

    disableRecurring: _ember['default'].computed('bookingRequestToEditIsSingleOccurrence', 'bookingType.id', function () {
      return this.get('bookingRequestToEditIsSingleOccurrence') || !this.can('recurring booking-type', { model: this.get('bookingType') });
    }),

    canEditEndDateTimeOnly: _ember['default'].computed.alias('model.canEditEndDateTimeOnly'),

    init: function init() {
      this._super.apply(this, arguments);
      // this.send('updateContentForFromTime', true);
      // this.send('updateContentForToTime', true);
    },

    didInsertElement: function didInsertElement() {
      if (!this.get('model.name')) this.$().find('.bookingRequestForm-title-input').focus();
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
    },

    submit: function submit(event) {
      event.preventDefault();
      this.send('save');
    },

    actions: {

      updateBookingMode: function updateBookingMode(mode) {
        this.set('bookingMode', mode);
      },

      onTabClick: function onTabClick(section) {
        var _this = this;

        var sectionId = section.get('identity');
        var $section = _ember['default'].$('.collapse[data-collapse-id="' + sectionId + '"]');
        var sectionIsCollapsed = $section.attr('aria-expanded') === 'false';
        var $firstFieldInSection = $section.find('.bookingRequestForm-field').first();
        if (sectionIsCollapsed && $firstFieldInSection) {
          (function () {
            var $parentDiv = _ember['default'].$('.lf-dialog-content');
            _ember['default'].run.later(_this, function () {
              var calc = $parentDiv.scrollTop() + $firstFieldInSection.position().top - $parentDiv.height() / 2 + $firstFieldInSection.height() / 2;
              $parentDiv.animate({ scrollTop: calc });
            }, 500);
          })();
        }
      },

      // jshint maxcomplexity:21
      save: function save() {
        var _this2 = this;

        // this.set('isSaving', true);
        if (this.get('bookingForm') && this.get('bookingForm.sections')) {
          var _ret2 = (function () {
            var customFieldsAreValid = true;

            var firstValidationError = undefined;

            _this2.get('bookingForm.sections').forEach(function (section) {
              section.get('fields').forEach(function (field) {
                var _field$validateSync = field.validateSync();

                var validations = _field$validateSync.validations;

                if (!validations.get('isValid')) {
                  // console.log(field.get('description'), field, validations.get('errors'));
                  if (!firstValidationError) {
                    firstValidationError = { sectionId: section.get('identity'), fieldId: field.get('identity') };
                    _this2._highlightFieldValidationError(firstValidationError);
                  }
                  customFieldsAreValid = false;
                }
              });
            });

            _this2.set('didValidate', true);
            if (!customFieldsAreValid) return {
                v: undefined
              };
          })();

          if (typeof _ret2 === 'object') return _ret2.v;
        }

        if (!this._validateDefaultFields()) return;

        var description = this.get('model.description');
        var isRepeatBooking = this.get('repeatBooking');

        var startTime = isRepeatBooking ? this.get('model.recurrencePattern.fromTime') : this.get('model.fromTime');
        var endTime = isRepeatBooking ? this.get('model.recurrencePattern.toTime') : this.get('model.toTime');

        var _startTime$split = startTime.split(':');

        var _startTime$split2 = _slicedToArray(_startTime$split, 2);

        var startHours = _startTime$split2[0];
        var startMinutes = _startTime$split2[1];

        var _endTime$split = endTime.split(':');

        var _endTime$split2 = _slicedToArray(_endTime$split, 2);

        var endHours = _endTime$split2[0];
        var endMinutes = _endTime$split2[1];

        // Get start Date depending if it is a recurring booking
        var startDate = isRepeatBooking ? this.get('model.recurrencePattern.startDate') : this.get('model.startDateTime');

        // Get end date depending if it is a recurring booking. This is not a typo, we are using startDate
        // here as well because we don't want to allow a different endDate from the startDate (not allow spanning days)
        var endDate = isRepeatBooking ? this.get('model.recurrencePattern.startDate').clone() : this.get('model.endDateTime');

        // Override endDate with startDate if it has externallyManagedResources as we do not allow span multiple days
        if (this.get('bookingType.hasExternallyManagedResources')) endDate = startDate.clone();

        var recurrencePattern = this.get('model.recurrencePattern');
        var recurrenceGenerated = undefined;

        // Validate Pattern if is set to Recurring booking
        if (isRepeatBooking) {
          recurrenceGenerated = this._validateRecurrencePattern(recurrencePattern);
          if (!recurrenceGenerated) return;
        }
        var startDateTime = startDate.clone().hours(startHours).minutes(startMinutes);

        var endDateTime = endDate.clone().hours(endHours).minutes(endMinutes);

        if (!this._validateDateTimes(startDateTime, endDateTime)) return;

        var params = {
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          recurrencePattern: recurrenceGenerated,
          description: description,
          name: this.get('model.name')
        };

        if (!this._applyBoBoAndBaseSettings(params)) return;

        var isOccurrence = this.get('bookingRequestToEditIsSingleOccurrence');
        if (this.get('isEditing')) {
          // If its a single occurrence edit, set parentId (groupId) and occurrenceId (id)
          if (isOccurrence) {
            params.occurrenceId = this.get('model.id');
            params.parentId = this.get('model.occurrenceGroupId');
          } else {
            params.bookingRequestId = this.get('model.id');
          }
        }

        var deferred = _ember['default'].RSVP.defer();

        // Check if isEditing and if isEditing a singleOccurrence or normal booking
        if (this.get('isEditing')) {
          this.sendAction(isOccurrence ? 'updateSingle' : 'update', params, deferred);
        } else {
          // Else is a normal booking
          this.sendAction('book', params, deferred);
        }
      },

      toggleUpdateOccurrencePattern: function toggleUpdateOccurrencePattern() {
        this.toggleProperty('updateOccurrencePattern');
      }

    },

    _validateDefaultFields: function _validateDefaultFields() {
      if (!this.get('model.name') || _ember['default'].isEmpty(this.get('model.name').trim())) {
        this._displayError(this.get('i18n').t('components.booking_request_form.title_required'));
        return false;
      }
      if (!this.get('model.fromTime') || _ember['default'].isEmpty(this.get('model.fromTime').trim())) {
        this._displayError(this.get('i18n').t('components.booking_request_form.from_time_required'));
        return false;
      }
      if (!this.get('model.toTime') || _ember['default'].isEmpty(this.get('model.toTime').trim())) {
        this._displayError(this.get('i18n').t('components.booking_request_form.to_time_required'));
        return false;
      }

      return true;
    },

    _validateDateTimes: function _validateDateTimes(start, end) {
      if (end.hour() !== 0 && (end.isBefore(start) || end.isSame(start, 'minute'))) {
        this._displayError(this.get('i18n').t('components.booking_request_form.to_time_before_start_time'));
        return false;
      }

      return true;
    },

    _validateRecurrencePattern: function _validateRecurrencePattern(pattern) {
      var errors = this.get('recurrenceProcessor').validate(pattern);
      if (!_ember['default'].isEmpty(errors)) {
        if (errors.includes('to_time')) {
          this._displayError(this.get('i18n').t('components.booking_request_form.to_time_before_start_time'));
        } else {
          this._displayError(this.get('i18n').t('components.booking_request_form.recurring_error'));
        }
        return false;
      }

      // return true;
      return this.get('recurrenceProcessor').generate(pattern);
    },

    _displayError: function _displayError(errorMessage) {
      this.set('formError', errorMessage);
      _ember['default'].$('.lf-dialog-content').animate({ scrollTop: 0 }, 'slow');
    },

    _applyBoBoAndBaseSettings: function _applyBoBoAndBaseSettings(params) {
      // BOBO feature
      if (this.get('boboBaseSettings.bookOnBehalfOfChecked')) {
        var emailField = this.get('boboBaseSettings.bookOnBehalfOfText');

        if (_ember['default'].isEmpty(emailField.trim())) {
          this._displayError(this.get('i18n').t('components.booking_request_form.bobo.no_emails'));
          return false;
        }

        var emails = this._checkEmailAddresses(emailField);
        if (emails.hasInvalidEmails) {
          this._displayError(this.get('i18n').t('components.booking_request_form.bobo.invalid_emails', { emails: emails.invalidEmails }));
          return false;
        }

        params.additionalEmailAddresses = emails.emailAddresses;
      }

      // BASE feature
      if (this.get('boboBaseSettings.bookAsSomeoneElseChecked')) {
        var ownerField = this.get('boboBaseSettings.bookAsSomeoneElseOwner');
        var emailField = this.get('boboBaseSettings.bookAsSomeoneElseEmail');
        var ownerNameField = this.get('boboBaseSettings.bookAsSomeoneElseName');

        if (_ember['default'].isEmpty(ownerField.trim()) || _ember['default'].isEmpty(emailField.trim()) || _ember['default'].isEmpty(ownerNameField.trim())) {
          this._displayError(this.get('i18n').t('components.booking_request_form.base.no_identity_or_email'));
          return false;
        }

        var emails = this._checkEmailAddresses(emailField);
        if (emails.hasInvalidEmails) {
          this._displayError(this.get('i18n').t('components.booking_request_form.bobo.invalid_emails', { emails: emails.invalidEmails }));
          return false;
        }

        params.owner = ownerField;
        params.bookerEmailAddress = emailField;
        params.bookerName = ownerNameField;
      }

      return true;
    },

    _checkEmailAddresses: function _checkEmailAddresses(emails) {
      var emailAddresses = emails.split('\n');
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var invalidEmails = [];

      emailAddresses = emailAddresses.map(function (email) {
        email = email.trim();
        if (!email.match(emailRegex)) invalidEmails.push(email);
        return email;
      });

      var response = {
        emailAddresses: emailAddresses,
        hasInvalidEmails: !_ember['default'].isEmpty(invalidEmails),
        invalidEmails: invalidEmails.join(', ')
      };

      return response;
    },

    _highlightFieldValidationError: function _highlightFieldValidationError(_ref) {
      var _this3 = this;

      var sectionId = _ref.sectionId;
      var fieldId = _ref.fieldId;

      var $section = _ember['default'].$('.collapse[data-collapse-id="' + sectionId + '"]');
      var sectionIsCollapsed = $section.attr('aria-expanded') === 'false';
      if (sectionIsCollapsed) {
        $section.collapse('show');
      }

      var $field = _ember['default'].$('.bookingRequestForm-field[data-field-id=' + fieldId + ']');

      if ($field.length) {
        (function () {
          var $parentDiv = _ember['default'].$('.lf-dialog-content');
          var calc = undefined;
          // Make sure we give enough time for the section to expand before scrolling to the field or the calc will not be correct
          if (!sectionIsCollapsed) {
            calc = $parentDiv.scrollTop() + $field.position().top - $parentDiv.height() / 2 + $field.height() / 2;
            $parentDiv.animate({ scrollTop: calc });
          } else {
            _ember['default'].run.later(_this3, function () {
              calc = $parentDiv.scrollTop() + $field.position().top - $parentDiv.height() / 2 + $field.height() / 2;
              $parentDiv.animate({ scrollTop: calc });
            }, 1000);
          }
        })();
      }
    },

    _localePrefix: function _localePrefix() {
      if (this.get('isEditing')) {
        return 'components.booking_request_form.edit';
      } else {
        return 'components.booking_request_form.create';
      }
    }
  });
});