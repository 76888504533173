define("scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 6
              },
              "end": {
                "line": 31,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "time-input", [], ["startHour", ["subexpr", "@mut", [["get", "startHour", ["loc", [null, [25, 18], [25, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "endHour", ["subexpr", "@mut", [["get", "endHour", ["loc", [null, [26, 16], [26, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.fromTime", ["loc", [null, [27, 30], [27, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [27, 25], [27, 45]]], 0, 0]], [], ["loc", [null, [27, 17], [27, 46]]], 0, 0], "calendarOptions", ["subexpr", "@mut", [["get", "calendarOptions", ["loc", [null, [28, 24], [28, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.fromTime", ["loc", [null, [29, 14], [29, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "at-startDate bookingRequestForm-input bookingRequestForm-input--timeInput"], ["loc", [null, [24, 8], [30, 91]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 6
              },
              "end": {
                "line": 39,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "time-input-select", [], ["content", ["subexpr", "@mut", [["get", "contentFromTime", ["loc", [null, [33, 16], [33, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "canEditEndDateTimeOnly", ["loc", [null, [34, 17], [34, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "readonly", [["get", "model.fromTime", ["loc", [null, [35, 24], [35, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 14], [35, 39]]], 0, 0], "location", "at-bookingForm", "onChange", ["subexpr", "action", ["updateFromTime"], [], ["loc", [null, [37, 17], [37, 43]]], 0, 0]], ["loc", [null, [32, 8], [38, 10]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 8
                },
                "end": {
                  "line": 61,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "time-input", [], ["startHour", ["subexpr", "@mut", [["get", "startHour", ["loc", [null, [55, 20], [55, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "endHour", ["subexpr", "@mut", [["get", "endHour", ["loc", [null, [56, 18], [56, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.toTime", ["loc", [null, [57, 16], [57, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "calendarOptions", ["subexpr", "@mut", [["get", "calendarOptions", ["loc", [null, [58, 26], [58, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.toTime", ["loc", [null, [59, 32], [59, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [59, 27], [59, 45]]], 0, 0]], [], ["loc", [null, [59, 19], [59, 46]]], 0, 0], "class", "at-endDate--limited bookingRequestForm-input bookingRequestForm-input--timeInput"], ["loc", [null, [54, 10], [60, 100]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 8
                },
                "end": {
                  "line": 68,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "time-input-select", [], ["content", ["subexpr", "@mut", [["get", "contentToTime", ["loc", [null, [63, 18], [63, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "readonly", [["get", "model.toTime", ["loc", [null, [64, 26], [64, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [64, 16], [64, 39]]], 0, 0], "location", "at-bookingForm", "onChange", ["subexpr", "action", ["updateToTime"], [], ["loc", [null, [66, 19], [66, 42]]], 0, 0]], ["loc", [null, [62, 10], [67, 12]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 6
              },
              "end": {
                "line": 70,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "date-input bookingRequestForm-input");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2, "disabled", "true");
            dom.setAttribute(el2, "class", "form-input");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'aria-label');
            morphs[1] = dom.createAttrMorph(element0, 'value');
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.booking_request_form.to"], [], ["loc", [null, [49, 27], [49, 69]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["subexpr", "moment-format", [["get", "model.startDateTime", ["loc", [null, [49, 93], [49, 112]]], 0, 0, 0, 0], ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [49, 119], [49, 147]]], 0, 0]], [], ["loc", [null, [49, 113], [49, 148]]], 0, 0]], [], ["loc", [null, [null, null], [49, 150]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "useOldTimePicker", ["loc", [null, [53, 14], [53, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [53, 8], [68, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 88,
                  "column": 8
                },
                "end": {
                  "line": 97,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "time-input", [], ["calendarOptions", ["subexpr", "@mut", [["get", "calendarOptions", ["loc", [null, [90, 26], [90, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "startHour", ["subexpr", "@mut", [["get", "startHour", ["loc", [null, [91, 20], [91, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "endHour", ["subexpr", "@mut", [["get", "endHour", ["loc", [null, [92, 18], [92, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.toTime", ["loc", [null, [93, 16], [93, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.toTime", ["loc", [null, [94, 32], [94, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [94, 27], [94, 45]]], 0, 0]], [], ["loc", [null, [94, 19], [94, 46]]], 0, 0], "class", "at-endDate bookingRequestForm-input bookingRequestForm-input--timeInput"], ["loc", [null, [89, 10], [95, 91]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 97,
                  "column": 8
                },
                "end": {
                  "line": 104,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "time-input-select", [], ["content", ["subexpr", "@mut", [["get", "contentToTime", ["loc", [null, [99, 18], [99, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "readonly", [["get", "model.toTime", ["loc", [null, [100, 26], [100, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [100, 16], [100, 39]]], 0, 0], "location", "at-bookingForm", "onChange", ["subexpr", "action", ["updateToTime"], [], ["loc", [null, [102, 19], [102, 43]]], 0, 0]], ["loc", [null, [98, 10], [103, 12]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 70,
                "column": 6
              },
              "end": {
                "line": 106,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "bookingRequestForm-input date-input");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
            morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            return morphs;
          },
          statements: [["inline", "bs-datetimepicker", [], ["date", ["subexpr", "@mut", [["get", "model.endDateTime", ["loc", [null, [75, 13], [75, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "noIcon", true, "disabled", ["subexpr", "@mut", [["get", "canEditEndDateTimeOnly", ["loc", [null, [77, 17], [77, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "format", ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [78, 21], [78, 49]]], 0, 0]], [], ["loc", [null, [78, 15], [78, 50]]], 0, 0], "updateDate", ["subexpr", "action", ["updateEndDate"], [], ["loc", [null, [79, 19], [79, 43]]], 0, 0], "readonly", true, "daysOfWeekDisabled", ["subexpr", "@mut", [["get", "calendarDaysDisabled", ["loc", [null, [81, 27], [81, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "position", "left,auto", "class", "at-bookingRequestForm", "allowInputToggle", true], ["loc", [null, [74, 8], [84, 31]]], 0, 0], ["block", "if", [["get", "useOldTimePicker", ["loc", [null, [88, 14], [88, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [88, 8], [104, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 109,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestForm-label");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "bookingRequestForm-label--inlineItems");
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "bookingRequestForm-input date-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestForm-label");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "bookingRequestForm-label--inlineItems");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [3]);
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [2]), 1, 1);
          morphs[2] = dom.createMorphAt(element2, 4, 4);
          morphs[3] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["components.booking_request_form.from"], [], ["loc", [null, [4, 83], [4, 127]]], 0, 0], ["inline", "bs-datetimepicker", [], ["date", ["subexpr", "@mut", [["get", "model.startDateTime", ["loc", [null, [11, 13], [11, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "noIcon", true, "format", ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [13, 21], [13, 49]]], 0, 0]], [], ["loc", [null, [13, 15], [13, 50]]], 0, 0], "updateDate", ["subexpr", "action", ["updateStartDate"], [], ["loc", [null, [14, 19], [14, 45]]], 0, 0], "readonly", true, "disabled", ["subexpr", "@mut", [["get", "canEditEndDateTimeOnly", ["loc", [null, [16, 17], [16, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "daysOfWeekDisabled", ["subexpr", "@mut", [["get", "calendarDaysDisabled", ["loc", [null, [17, 27], [17, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "position", "left,auto", "class", "at-bookingRequestForm", "allowInputToggle", true], ["loc", [null, [10, 8], [20, 31]]], 0, 0], ["block", "if", [["get", "useOldTimePicker", ["loc", [null, [23, 12], [23, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [23, 6], [39, 13]]]], ["inline", "t", ["components.booking_request_form.to"], [], ["loc", [null, [44, 83], [44, 125]]], 0, 0], ["block", "if", [["subexpr", "or", [["get", "bookingType.hasExternallyManagedResources", ["loc", [null, [47, 16], [47, 57]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "calendarOptions.bookingSpanMultipleDays", ["loc", [null, [47, 63], [47, 102]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 58], [47, 103]]], 0, 0]], [], ["loc", [null, [47, 12], [47, 104]]], 0, 0]], [], 2, 3, ["loc", [null, [47, 6], [106, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 109,
              "column": 0
            },
            "end": {
              "line": 111,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "loading-icon", [], ["size", "medium", "align", "center"], ["loc", [null, [110, 2], [110, 47]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 115,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "display:none; font-size: 14px;");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" || ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "display:none; font-size: 14px;");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" || ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var element5 = dom.childAt(fragment, [2]);
        var element6 = dom.childAt(fragment, [4]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element4, 'class');
        morphs[1] = dom.createMorphAt(element4, 1, 1);
        morphs[2] = dom.createMorphAt(element5, 0, 0);
        morphs[3] = dom.createMorphAt(element5, 2, 2);
        morphs[4] = dom.createMorphAt(element6, 0, 0);
        morphs[5] = dom.createMorphAt(element6, 2, 2);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["bookingRequestForm-fields--datetime-contentWrapper ", ["subexpr", "if", [["get", "isLoading", ["loc", [null, [1, 68], [1, 77]]], 0, 0, 0, 0], "bookingRequestForm-fields--datetime-contentWrapper--loading", ""], [], ["loc", [null, [1, 63], [1, 144]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "unless", [["get", "isLoading", ["loc", [null, [2, 12], [2, 21]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [111, 11]]]], ["inline", "moment-format", [["get", "model.startDateTime", ["loc", [null, [113, 60], [113, 79]]], 0, 0, 0, 0], "DD/MM/YYYY HH:mm"], [], ["loc", [null, [113, 44], [113, 100]]], 0, 0], ["content", "model.fromTime", ["loc", [null, [113, 104], [113, 122]]], 0, 0, 0, 0], ["inline", "moment-format", [["get", "model.endDateTime", ["loc", [null, [114, 60], [114, 77]]], 0, 0, 0, 0], "DD/MM/YYYY HH:mm"], [], ["loc", [null, [114, 44], [114, 98]]], 0, 0], ["content", "model.toTime", ["loc", [null, [114, 102], [114, 118]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});