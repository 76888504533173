define('scientia-resourcebooker/pods/logged-in/resource/show/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/unavailability-types'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsUnavailabilityTypes) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var HOUR_FORMAT = 'HH:mm';
  var DATE_FORMAT = 'YYYY-MM-DD';
  var BOOKING_MODES = { SINGLE: 1, RECURRING: 2 };

  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsUnavailabilityTypes['default'], _scientiaResourcebookerMixinsFlashMessages['default'], {

    localeProcessor: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    calendarOptionsProcessor: _ember['default'].inject.service(),
    can: _ember['default'].inject.service(),

    applicationController: _ember['default'].inject.controller('application'),
    isLoading: _ember['default'].computed.bool('applicationController.loading'),

    canShowCalendar: _ember['default'].computed.alias('localeProcessor.canShowCalendar'),

    queryParams: ['bookingId', 'occurrenceId', 'fromTime', 'toTime', {
      'editFromPage': 'from'
    }, {
      'editCalendarViewType': 'view'
    }, {
      formattedDisplayedDate: 'date'
    }, {
      bookingSource: 'source'
    }, 'toDate', 'isConfiguration', 'concurrencyId'],

    // Which external system the user is coming from (RBT, SEL, etc)
    bookingSource: 'RB',

    // Where the Edit request was origined from ( my-bookings/unavailability/admin)
    editFromPage: null,

    displayedDate: _ember['default'].computed('formattedDisplayedDate', {
      get: function get() {
        return this.get('formattedDisplayedDate') ? moment(this.get('formattedDisplayedDate')) : null;
      },
      set: function set(key, value) {
        this.set('formattedDisplayedDate', value ? moment(value).format(DATE_FORMAT) : '');
      }
    }),

    formattedDisplayedDate: null,
    toDate: null,

    bookingRequestCreator: _ember['default'].inject.service(),
    bookingRequestUpdater: _ember['default'].inject.service(),
    bookingRequestDestroyer: _ember['default'].inject.service(),
    bookingRequestErrorHandler: _ember['default'].inject.service(),
    resourceBookingRequestsFetcher: _ember['default'].inject.service(),
    calendarOptionsHelper: _ember['default'].inject.service(),

    userSearcher: _ember['default'].inject.service(),
    resourceEventsFetcher: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),
    additionalResourcesSearcher: _ember['default'].inject.service(),
    recurrenceProcessor: _ember['default'].inject.service(),
    unavailabilityEventFetcher: _ember['default'].inject.service(),
    bookingTypeShowController: _ember['default'].inject.controller('logged-in.booking-type.show'),
    myBookingsController: _ember['default'].inject.controller('logged-in.booking-request.index'),
    showDayViewCalendarModal: null,

    showBookingRequestForm: false,

    showBookingTypeHelpMessageModal: false,
    showInvalidBookingTimesModal: false,

    showAdjustedTimesBanner: false,

    isEditing: _ember['default'].computed.and('bookingId', 'model.bookingRequest'),

    bookingId: '',
    occurrenceId: '',
    fromTime: '',
    toTime: '',

    bookingMode: 1, // Default selected mode "Single" or "Recurring"
    editMode: 1,

    calendarViewType: null,
    showShowAvailabilityButton: _ember['default'].computed('calendarViewType', function () {
      return this.get('calendarViewType') === 'week' || this.get('calendarViewType') === 'day';
    }),

    // Which view was the user on when they choose to edit a booking
    editCalendarViewType: null,

    bookingRequestToEditIsSingleOccurrence: _ember['default'].computed.bool('occurrenceId'),

    shouldShowSidebar: _ember['default'].computed.gt('model.bookingType.resourceRestrictionRules.length', 1),

    // If the item has at least one non null property or a valid description.
    hasMoreInfos: (function () {
      var hasNonNullProperty = this.get('model.resource.properties').any(function (property) {
        if (property.get('valueType') === 'yesno') return true;

        return property.get('value') != null;
      });

      return hasNonNullProperty || this.get('model.resource.description') && this.get('model.resource.description') !== '';
    }).property('model.resource.properties.[]', 'model.resource.description'),

    isShowingAvailability: false,

    isSavingForm: false,
    editRestrictionsModal: null,

    boboBaseSettings: {
      bookAsSomeoneElseChecked: false,
      bookAsSomeoneElseOwner: '',
      bookAsSomeoneElseEmail: '',
      bookAsSomeoneElseName: '',
      bookOnBehalfOfChecked: false,
      bookOnBehalfOfText: ''
    },

    isUsingCustomForm: _ember['default'].computed.bool('model.bookingForm'),
    customFormSingleSection: _ember['default'].computed.equal('model.bookingForm.sections.length', 1),
    customFormHasSingleSection: _ember['default'].computed.and('isUsingCustomForm', 'customFormSingleSection'),

    updateOccurrencePattern: false,

    // calendarOptions: Ember.computed.alias('model.bookingType.calendarOptions'),

    calendarOptions: _ember['default'].computed('model.bookingType.calendarOptions', 'model.bookingType.id', 'model.useCustomCalendarOptions', function () {
      var bt = this.get('model.bookingType');
      if (bt.get('calendarOptions.useCustomCalendarOptions')) {
        return bt.get('calendarOptions');
      } else {
        return this.get('systemSettings').getCalendarOptions();
      }
    }),

    locationClassNames: _ember['default'].computed('shouldShowSidebar', 'bookingRequestToEditIsSingleOccurrence', function () {
      var className = 'at-resource';
      if (!this.get('shouldShowSidebar') || this.get('bookingRequestToEditIsSingleOccurrence')) {
        className += ' at-resource--fullWidth';
      }
      return className;
    }),

    sectionTitle: _ember['default'].computed('isEditing', 'model.resource.name', function () {
      if (this.get('isEditing')) {
        var resourceName = this.get('model.bookingRequest.name');
        return this.get('i18n').t('pods.logged_in.resource.show.editing_booking', {
          name: '' + resourceName.substring(0, 36) + (resourceName.length >= 36 ? '...' : '')
        });
      } else {
        var resourceName = this.get('model.resource.name');
        return '' + resourceName.substring(0, 60) + (resourceName.length >= 60 ? '...' : '');
      }
    }),

    sectionTitleIcon: _ember['default'].computed('isEditing', function () {
      return this.get('isEditing') ? 'icons/edit.svg' : this.get('model.resource.imageUri');
    }),

    requiredResources: _ember['default'].computed.filter('model.bookingType.resourceRestrictionRules', function (resourceRestriction) {
      var rules = this.get('model.bookingType.resourceRestrictionRules');
      if (rules.length === 1 && rules.get('firstObject.min') <= 1) return false;

      return resourceRestriction.get('min') > 0 && !resourceRestriction.get('isPrimary');
    }),

    possibleResources: _ember['default'].computed.filter('model.bookingType.resourceRestrictionRules', function (resourceRestriction) {
      return resourceRestriction.get('min') === 0 && !resourceRestriction.get('isPrimary');
    }),

    chosenRequiredResources: [],
    chosenPossibleResources: [],

    chosenResources: _ember['default'].computed('chosenRequiredResources.length', 'chosenPossibleResources.length', function () {
      return this.get('chosenRequiredResources').concat(this.get('chosenPossibleResources'));
    }),

    currentTimeSpanStart: null,
    currentTimeSpanEnd: null,

    currentUserSearchResultsPage: 1,
    currentUserSearchQuery: '',

    resourcesSelected: [],

    resetProperties: function resetProperties() {

      if (this.get('model.bookingForm')) {
        try {
          this.get('model.bookingForm').rollbackAttributes();
        } catch (e) {
          //no-op
        }
      }

      this.setProperties({
        showDayViewCalendarModal: null,
        currentTimeSpanStart: null,
        currentTimeSpanEnd: null,

        currentUserSearchResultsPage: 1,
        currentUserSearchQuery: '',
        recurrenceCheckboxChecked: false,
        bookingMode: 1,
        bookingSource: 'RB',
        bookingRequest: null,
        bookingId: '',
        editFromPage: null,
        occurrenceId: '',
        updateOccurrencePattern: false,
        editRestrictionsModal: null,
        resourcesSelected: [],
        boboBaseSettings: {
          bookAsSomeoneElseChecked: false,
          bookAsSomeoneElseOwner: '',
          bookAsSomeoneElseEmail: '',
          bookAsSomeoneElseName: '',
          bookOnBehalfOfChecked: false,
          bookOnBehalfOfText: ''
        },
        showBookingRequestForm: false,
        showBookingRequest: null,
        showEventOverview: null,
        formError: '',
        showAdjustedTimesBanner: false
      });
    },

    calendarEvents: _ember['default'].computed('displayedDate', 'model.events.[]', function () {
      var model = this.get('model.events');
      var date = moment(this.get('displayedDate'));
      return model.filter(function (booking) {
        var startDateTime = moment(booking.get('startDateTime')).startOf('day');
        var endDateTime = moment(booking.get('endDateTime')).startOf('day');
        return date.isSame(startDateTime) || date.isSame(endDateTime) || date.isBetween(startDateTime, endDateTime);
      });
    }),

    actions: {

      // When the calendar initially loads
      onCalendarLoad: function onCalendarLoad() {
        var fromTime = this.get('fromTime');
        var date = this.get('displayedDate');
        var toTime = this.get('toTime');
        var toDate = this.get('toDate') ? moment(this.get('toDate'), DATE_FORMAT) : null;

        // Check if we're editing
        if (this.get('isEditing')) {
          _ember['default'].run.next(this, 'send', 'openEditBookingRequestModal', null, fromTime, toTime, true);
        } else if (date && fromTime) {
          var allowSpanMultipleDays = date && toDate && date !== toDate;
          _ember['default'].run.next(this, 'send', 'openCreateBookingRequestModal', date, fromTime, toTime, toDate, allowSpanMultipleDays);
        }
      },

      setDisplayedDate: function setDisplayedDate(displayedDate) {
        this.set('formattedDisplayedDate', displayedDate.format(DATE_FORMAT));
      },

      // Triggered whenever we click on a calendar slot
      onCalendarDateClick: function onCalendarDateClick(date) {

        // Either edit or create new if there is a bookingId
        if (this.get('isEditing')) {
          this.send('openEditBookingRequestModal', date, this.get('fromTime'), this.get('toTime'));
        } else {

          if (!this.get('isLoading')) {
            this.send('openCreateBookingRequestModal', date);
          }
        }
      },

      onDateRangeDrag: function onDateRangeDrag(startRange, endRange) {

        if (endRange.isBefore(startRange)) {
          var tmp = endRange.clone();
          endRange = startRange;
          startRange = tmp;
        }

        var diffInMinutes = endRange.diff(startRange, 'minutes');

        var calendarOptions = this.get('calendarOptions');

        startRange = this.get('calendarOptionsHelper').adjustStartDateTimePerCalendarOptions(startRange, calendarOptions);

        endRange = startRange.clone().add(diffInMinutes, 'minutes').add(1, 'hour');

        // If end range has gone to the next day (00:xx) then reset to midnight
        if (!endRange.isSame(startRange, 'day')) {
          endRange.startOf('day');
        }

        endRange = this.get('calendarOptionsHelper').adjustEndDateTimePerCalendarOptions(startRange, endRange, calendarOptions);

        var fromTime = startRange.format('HH:mm');
        var toTime = endRange.format('HH:mm');

        var originalEndRange = endRange.clone();

        if (toTime === '00:00') {
          endRange.subtract(1, 'day');
          toTime = '24:00';
        }

        this.get('appInsights').trackEvent('calendar-drag', {
          fromTime: fromTime,
          toTime: toTime,
          isAdmin: this.get('currentUser').isAdmin(),
          isEditing: !!this.get('isEditing')
        });

        // Check if we are dragging over an existing booking/unav slot.
        var events = this.get('model.events');
        var eventFound = events.find(function (e) {
          var eventStart = _ember['default'].get(e, 'startDateTime');
          var eventEnd = _ember['default'].get(e, 'endDateTime');
          return eventStart.isBetween(startRange, originalEndRange, 'minute') || eventEnd.isBetween(startRange, originalEndRange);
        });

        // Return false indicating to the component that called this action that the selection is invalid
        if (eventFound) {
          return false;
        }

        if (this.get('isEditing')) {
          this.send('openEditBookingRequestModal', startRange, fromTime, toTime);
        } else {

          if (!this.get('isLoading')) {
            this.send('openCreateBookingRequestModal', startRange, fromTime, toTime, endRange);
          }
        }

        return true; // return true to indicate the selection was valid
      },

      openDayViewCalendarModal: function openDayViewCalendarModal(date) {
        if (this.get('isLoading')) return;

        this.setProperties({
          formattedDisplayedDate: date.format(DATE_FORMAT),
          showDayViewCalendarModal: date
        });
      },

      //jshint maxcomplexity:22
      openEditBookingRequestModal: function openEditBookingRequestModal(clickedDate, fromTime, toTime, isOnLoad) {

        var calendarOptions = this.get('calendarOptions');

        var booking = this.get('model.bookingRequest');

        var startDate = clickedDate ? clickedDate.clone() : booking.get('startDateTime');
        var endDate = clickedDate ? clickedDate.clone().add(1, 'hour') : booking.get('endDateTime');

        var isBookingTimesValid = this.get('calendarOptionsHelper').isBookingTimesValidForCalendarOptions(startDate, endDate, calendarOptions);

        if (!isBookingTimesValid) {
          var _get$adjustBookingEditStartAndEndDateTimePerCalendarOptions = this.get('calendarOptionsHelper').adjustBookingEditStartAndEndDateTimePerCalendarOptions(startDate, endDate, calendarOptions);

          var _get$adjustBookingEditStartAndEndDateTimePerCalendarOptions2 = _slicedToArray(_get$adjustBookingEditStartAndEndDateTimePerCalendarOptions, 2);

          startDate = _get$adjustBookingEditStartAndEndDateTimePerCalendarOptions2[0];
          endDate = _get$adjustBookingEditStartAndEndDateTimePerCalendarOptions2[1];
        }

        fromTime = fromTime || startDate.format(HOUR_FORMAT);
        toTime = toTime || endDate.format(HOUR_FORMAT);

        var patternExists = booking.get('recurrencePattern');
        var hasRecurrence = booking.get('hasRecurrence');

        if (toTime === '00:00') {
          endDate.subtract(1, 'day');
          toTime = '24:00';
        }

        booking.setProperties({
          startDateTime: startDate,
          endDateTime: endDate,
          fromTime: fromTime,
          toTime: toTime
        });

        // If the booking does not have a recurrencePattern yet create it
        if (!patternExists) {
          var newPattern = this.get('recurrencePattern') || this.get('store').createFragment('recurrence-pattern');
          booking.set('recurrencePattern', this.get('recurrenceProcessor').copyFragment(newPattern));
        }

        var recurrence = booking.get('recurrencePattern');
        var recurrenceStartDate = recurrence.get('startDate') || startDate;
        recurrence.setProperties({
          startDate: recurrenceStartDate,
          fromTime: fromTime,
          toTime: toTime
        });

        var showingRecurrenceTab = hasRecurrence && !this.get('bookingRequestToEditIsSingleOccurrence');
        this.set('bookingMode', showingRecurrenceTab ? BOOKING_MODES.RECURRING : BOOKING_MODES.SINGLE);

        if (isOnLoad) this._setEditedResourcesOnLoad();

        var bookingForm = this.get('model.bookingForm');
        if (bookingForm && booking.get('bookingRequestFormValue')) {
          this._setBookingFormEditedValues();
        }

        var isBookingTimesOnTheHour = startDate.minute() === 0 && endDate.minute() === 0;

        _ember['default'].run.next(this, function () {
          this.set('bookingRequest', booking);
          this.set('showBookingRequestForm', true);

          if (!isBookingTimesValid && isOnLoad) {
            this.set('showInvalidBookingTimesModal', true);
          } else if (clickedDate && !isBookingTimesOnTheHour) {
            this._highlightAdjustedTimeInputs();
          }
        });
      },

      editBookingRequestFromUnavailabilitySlot: function editBookingRequestFromUnavailabilitySlot(bookingRequest) {
        var singleOccurrenceEdit = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

        var _this = this;

        var editRestrictions = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];
        var refittedResources = arguments.length <= 3 || arguments[3] === undefined ? null : arguments[3];

        if (bookingRequest.get('bookingTypeId') !== this.get('model.bookingType.id')) {
          var _bookingId = bookingRequest.get('eventId');
          var _queryParams = {
            bookingId: _bookingId,
            date: moment(bookingRequest.get('startDateTime')).format('YYYY-MM-DD'),
            editFromPage: 'unavailability'
          };

          var _occurrenceId = singleOccurrenceEdit ? bookingRequest.get('id') : null;
          if (_occurrenceId) _queryParams.occurrenceId = _occurrenceId;

          this.set('showConfirmTransitionToBookingType', { bookingRequest: bookingRequest, queryParams: _queryParams, bookingTypeName: '..' });
          this.get('store').findRecord('booking-type', bookingRequest.get('bookingTypeId')).then(function (bookingType) {
            _this.set('showConfirmTransitionToBookingType.bookingTypeName', bookingType.get('name'));
          });

          return;
        }

        this.set('model.bookingRequest', bookingRequest);
        this.set('bookingMode', bookingRequest.get('hasRecurrence') && !singleOccurrenceEdit ? 2 : 1);
        var bookingId = bookingRequest.get('eventId');
        var occurrenceId = singleOccurrenceEdit ? bookingRequest.get('id') : null;

        var queryParams = {
          bookingId: bookingId,

          date: moment(bookingRequest.get('startDateTime')).format('YYYY-MM-DD'),
          editFromPage: 'unavailability'
        };

        if (occurrenceId) queryParams.occurrenceId = occurrenceId;

        // If no restrictions just show edit
        if (_ember['default'].isEmpty(editRestrictions)) {
          this.send('editBookingRequestFromUnavailabilitySlotConfirm', bookingRequest, queryParams, refittedResources);
          return;
        } else {

          // Else create a deferred promise which will be resolved or rejected depending if the user chooses to continue with the edit or cancel
          var deferred = _ember['default'].RSVP.defer();

          //If user confirms then proceed to edit
          deferred.promise.then(function () {
            _this.send('editBookingRequestFromUnavailabilitySlotConfirm', bookingRequest, queryParams, refittedResources, editRestrictions);
          })['catch'](function () {
            // no op
          })['finally'](function () {
            _this.set('editRestrictionsModal', null);
          });

          // Trigger the modal and pass in the deferred and restrictions
          this.set('editRestrictionsModal', {
            deferred: deferred,
            restrictions: editRestrictions,
            isOccurrence: singleOccurrenceEdit
          });
        }
      },

      editBookingRequestFromUnavailabilitySlotConfirm: function editBookingRequestFromUnavailabilitySlotConfirm(bookingRequest, queryParams, refittedResources, editRestrictions) {

        this.set('showBookingRequest', false);
        this.setProperties(queryParams);

        this._setEditedResourcesOnLoad(bookingRequest, refittedResources, editRestrictions);

        this.send('openEditBookingRequestModal', null, // clicked date
        bookingRequest.get('startDateTime').format(HOUR_FORMAT), bookingRequest.get('endDateTime').format(HOUR_FORMAT), false); // onload
      },

      cancelBookingRequestFromUnavailabilitySlot: function cancelBookingRequestFromUnavailabilitySlot(bookingRequest, cancelType) {
        var numberOfOcurrences = arguments.length <= 2 || arguments[2] === undefined ? 1 : arguments[2];

        var occurrencesCount = bookingRequest.get('hasRecurrence') ? numberOfOcurrences : null;
        this.set('bookingRequestScheduledForCancellation', { booking: bookingRequest, cancelType: cancelType, occurrencesCount: occurrencesCount });
      },

      confirmTransitionToBookingType: function confirmTransitionToBookingType(_ref) {
        var bookingRequest = _ref.bookingRequest;
        var queryParams = _ref.queryParams;

        this.setProperties({
          showBookingRequest: null,
          showConfirmTransitionToBookingType: null
        });
        return this.transitionToRoute('logged-in.resource.show', bookingRequest.get('bookingTypeId'), bookingRequest.get('resources.firstObject.id'), { queryParams: queryParams });
      },

      confirmBookingRequestCancellation: function confirmBookingRequestCancellation(bookingRequest, cancelType, note) {
        var _this2 = this;

        var localePath = 'pods.logged_in.booking_request.index.';

        this.get('bookingRequestDestroyer').destroyRequest(bookingRequest, cancelType, note).then(function () {

          _this2.deleteSuccessFlash('booking-request');

          var currentUserEmail = _this2.get('currentUser.user.email');
          var bookerEmail = bookingRequest.get('bookerEmailAddress');
          _this2.get('appInsights').trackEvent('booking-cancelled', {
            type: cancelType === 1 ? 'occurrence' : 'booking',
            isOwner: currentUserEmail === bookerEmail,
            bookingId: bookingRequest.get('id'),
            bookingName: bookingRequest.get('name'),
            from: 'unavailability'
          });

          _this2.send('fetchEvents', _this2.get('currentTimeSpanStart'), _this2.get('currentTimeSpanEnd'));
        })['catch'](function (error) {

          if (error.responseJSON) {

            // let errorMessage = this.get('errorHandler').buildErrorMessage(error.responseJSON, localePath + 'flash_messages');
            var errorMessage = _this2.get('bookingRequestErrorHandler').handle(error, localePath + 'flash_messages');
            errorMessage = errorMessage ? errorMessage.toString().replace(new RegExp('\<\/br\>', 'g'), ', ') : null;
            if (errorMessage) {
              _this2.flashError(errorMessage);
              return;
            }
          }

          _this2.deleteErrorFlash('booking-request');
        })['finally'](function () {
          _this2.set('bookingRequestScheduledForCancellation', null);
          _this2.set('showBookingRequest', null);
        });
      },

      openCreateBookingRequestModal: function openCreateBookingRequestModal(fromDate) {
        var fromTime = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
        var toTime = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];
        var toDate = arguments.length <= 3 || arguments[3] === undefined ? null : arguments[3];
        var allowSpanningDaysAdjustment = arguments.length <= 4 || arguments[4] === undefined ? false : arguments[4];

        if (!moment.isMoment(fromDate)) {
          fromDate = moment(fromDate).isValid() ? moment(fromDate) : moment();
        }

        if (fromTime) {
          var _fromTime$split = fromTime.split(':');

          var _fromTime$split2 = _slicedToArray(_fromTime$split, 2);

          var h = _fromTime$split2[0];
          var m = _fromTime$split2[1];

          fromDate.hour(h).minute(m).startOf('minute');
        }

        if (!toDate) {
          toDate = fromDate.clone().add(1, 'hour');
        }

        if (toTime) {
          var _toTime$split = toTime.split(':');

          var _toTime$split2 = _slicedToArray(_toTime$split, 2);

          var toHour = _toTime$split2[0];
          var toMin = _toTime$split2[1];

          toDate = toDate.hour(toHour).minutes(toMin);
        }

        // Get correct calendar options
        var calendarOptions = this.get('calendarOptions');

        // Adjust from and to dates

        // Fetch the currence recurrencePattern object

        var _get$getAdjustedTimesForOnSlotClick = this.get('calendarOptionsHelper').getAdjustedTimesForOnSlotClick(fromDate, toDate, calendarOptions, allowSpanningDaysAdjustment);

        var _get$getAdjustedTimesForOnSlotClick2 = _slicedToArray(_get$getAdjustedTimesForOnSlotClick, 2);

        fromDate = _get$getAdjustedTimesForOnSlotClick2[0];
        toDate = _get$getAdjustedTimesForOnSlotClick2[1];
        var recurrence = this.get('bookingRequest.recurrencePattern') || this.get('recurrencePattern');

        // Create if there is not one
        recurrence = recurrence ? recurrence : this.get('store').createFragment('recurrence-pattern');

        fromTime = fromDate.format(HOUR_FORMAT);
        toTime = toDate.format(HOUR_FORMAT);

        // Here we need to check if the toTime is midnight, if it is, we want to change the date to the previous day
        // and update time to 24:00
        if (toTime === '00:00') {
          toTime = '24:00';
          toDate.subtract(1, 'day');
        }

        // Update its properties from the clickedOn date
        recurrence.setProperties({
          startDate: fromDate,
          fromTime: fromDate.format(HOUR_FORMAT),
          toTime: toTime
        });

        var bookingRequest = this.get('bookingRequest');

        var booking = bookingRequest || this.get('store').createRecord('booking-request');

        booking.setProperties({
          startDateTime: fromDate,
          fromTime: fromTime,
          endDateTime: toDate,
          toTime: toTime,
          recurrencePattern: this.get('recurrenceProcessor').copyFragment(recurrence)
        });

        this.set('bookingRequest', booking);
        this.set('showBookingRequestForm', true);

        // Flash form time inputs to note that selected times do not end on the hour
        var isBookingTimesOnTheHour = fromDate.minute() === 0 && toDate.minute() === 0;
        if (!isBookingTimesOnTheHour) {
          this._highlightAdjustedTimeInputs();
        }
      },

      closeCreateBookingRequestModal: function closeCreateBookingRequestModal() {
        var isSaving = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

        if (isSaving) {
          return;
        }

        this.set('showBookingRequestForm', false);
        this.set('formError', '');

        // If we are editing a booking through clicking on a booking on the calendar then clear the resourcesSelected
        if (this.get('editFromPage') === 'unavailability') {
          this.set('resourcesSelected', []);
        }

        if (this.get('isEditing') && this.get('model.bookingForm')) {
          this.get('model.bookingForm').rollbackAttributes();
        }

        // If the modal is in edit mode and from unavailability (admin is editing it) leave edit mode
        if (this.get('editFromPage') === 'unavailability' && this.get('isEditing')) {
          this.send('leaveEditMode');
        }
      },

      createBookingRequest: function createBookingRequest(_ref2, deferred) {
        var _this3 = this;

        var startDateTime = _ref2.startDateTime;
        var endDateTime = _ref2.endDateTime;
        var description = _ref2.description;
        var name = _ref2.name;
        var additionalEmailAddresses = _ref2.additionalEmailAddresses;
        var recurrencePattern = _ref2.recurrencePattern;
        var owner = _ref2.owner;
        var bookerEmailAddress = _ref2.bookerEmailAddress;
        var bookerName = _ref2.bookerName;

        this.set('formError', null);
        this.set('isSavingForm', true);
        var resources = this._createResourcesObject();

        var requestData = {
          bookingTypeId: this.get('model.bookingType.id'),
          name: name,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          description: description,
          resources: resources,
          recurrencePattern: recurrencePattern,
          additionalEmailAddresses: additionalEmailAddresses,
          owner: owner,
          bookerEmailAddress: bookerEmailAddress,
          bookerName: bookerName,
          bookingSource: this.get('bookingSource')
        };

        if (this.get('model.bookingForm')) {
          requestData.formData = this._getFormValues();
        }

        this.get('bookingRequestCreator').createRequest(requestData).then(function (newBooking) {

          if (_this3.get('model.bookingForm')) {
            _this3.get('model.bookingForm').rollbackAttributes();
          }

          _this3.set('formError', null);
          _this3.set('chosenPossibleResources', []);
          _this3.set('chosenRequiredResources', []);

          _this3.createSuccessFlash('booking-request');
          _this3.set('showBookingRequestForm', false);

          _this3.get('appInsights').trackEvent('booking-created', {
            resourceId: _this3.get('model.resource.id'),
            resourceName: _this3.get('model.resource.name'),
            bookingTypeId: _this3.get('model.bookingType.id'),
            bookingTypeName: _this3.get('model.bookingType.name'),
            isRecurring: !_ember['default'].isEmpty(recurrencePattern),
            recurrenceType: recurrencePattern ? recurrencePattern.recurrenceType : null,
            startDate: startDateTime.format(),
            endDate: endDateTime.format(),
            duration: moment.duration(endDateTime.diff(startDateTime)).asMinutes()
          });

          // Decide where to redirect to based on ENV
          if (_this3.get('systemSettings').getSetting('bookingRequestAlternativeRedirect')) {
            _this3.transitionToRoute('logged-in.booking-type.index', {
              queryParams: {
                bookingID: newBooking.id
              }
            });
          } else {
            _this3.transitionToRoute('logged-in.booking-request', {
              queryParams: {
                formattedDisplayedDate: startDateTime.format(DATE_FORMAT),
                bookingId: newBooking.id,
                refreshCache: true
              }
            });
          }

          deferred.resolve();
          _this3.set('bookingRequest', null);
        })['catch'](function (jqXHR) {
          return _this3._handleErrors(jqXHR, deferred, requestData);
        })['finally'](function () {
          _this3.set('isSavingForm', false);
        });
      },

      updateBookingSingleOccurrence: function updateBookingSingleOccurrence(_ref3, deferred) {
        var _this4 = this;

        var parentId = _ref3.parentId;
        var occurrenceId = _ref3.occurrenceId;
        var startDateTime = _ref3.startDateTime;
        var endDateTime = _ref3.endDateTime;

        this.set('isSavingForm', true);

        var resources = this._createResourcesObject();

        var requestData = {
          parentId: parentId,
          occurrenceId: occurrenceId,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          resources: resources
        };
        this.get('bookingRequestUpdater').updateOccurrence(requestData).then(function (updatedOccurrence) {

          var oldOccurrence = _this4.get('store').peekRecord('booking-request', occurrenceId);
          if (oldOccurrence) _this4.get('store').unloadRecord(oldOccurrence);

          _this4.editSuccessFlash('booking-request');

          var currentUserEmail = _this4.get('currentUser.user.email');
          var bookerEmail = _this4.get('bookingRequest.bookerEmailAddress');
          _this4.get('appInsights').trackEvent('booking-edited', {
            type: 'occurrence',
            isOwner: currentUserEmail === bookerEmail,
            bookingId: parentId,
            occurrenceId: occurrenceId,
            resourceId: _this4.get('model.resource.id'),
            resourceName: _this4.get('model.resource.name'),
            bookingTypeId: _this4.get('model.bookingType.id'),
            bookingTypeName: _this4.get('model.bookingType.name'),
            from: _this4.get('editFromPage'),

            startDate: startDateTime.format(),
            endDate: endDateTime.format(),
            duration: moment.duration(endDateTime.diff(startDateTime)).asMinutes()
          });

          var editFromPage = _this4.get('editFromPage');

          if (editFromPage === 'manage-resources') {
            _this4.transitionToRoute('manage-resources.resource.show', _this4.get('model.resource.resourceTypeId'), _this4.get('model.resource.id'), {
              queryParams: {
                formattedDisplayedDate: startDateTime.format(DATE_FORMAT),
                bookingId: updatedOccurrence.eventId,
                occurrenceId: updatedOccurrence.id
              }
            });
          } else if (editFromPage === 'concurrency-calendar') {
            _this4.transitionToRoute('manage-resources.resource-type.concurrency.show', _this4.get('concurrencyId'), {
              queryParams: {
                formattedDisplayedDate: startDateTime.format(DATE_FORMAT),
                bookingId: updatedOccurrence.eventId,
                occurrenceId: updatedOccurrence.id,
                isConfiguration: _this4.get('isConfiguration')
              }
            });
          } else if (editFromPage === 'report') {
            _this4.transitionToRoute('manage-resources.reports.index', { queryParams: {
                section: 0,
                resume: true,
                bookingId: updatedOccurrence.eventId,
                occurrenceId: updatedOccurrence.id
              } });
          } else if (editFromPage === 'unavailability') {
            _this4.send('leaveEditMode');
          } else {

            _this4.transitionToRoute('logged-in.booking-request', {
              queryParams: {
                formattedDisplayedDate: startDateTime.format(DATE_FORMAT),
                refreshCache: true,
                bookingId: updatedOccurrence.eventId,
                occurrenceId: updatedOccurrence.id
              }
            });
          }
        })['catch'](function (jqXHR) {
          return _this4._handleErrors(jqXHR, deferred, requestData, true);
        })['finally'](function () {
          _this4.set('isSavingForm', false);
          deferred.resolve();
        });
      },

      updateBookingRequest: function updateBookingRequest(_ref4, deferred) {
        var _this5 = this;

        var bookingRequestId = _ref4.bookingRequestId;
        var startDateTime = _ref4.startDateTime;
        var endDateTime = _ref4.endDateTime;
        var description = _ref4.description;
        var name = _ref4.name;
        var recurrencePattern = _ref4.recurrencePattern;

        this.set('formError', '');
        this.set('isSavingForm', true);

        var resources = this._createResourcesObject();
        var mainResource = this.get('model.resource');

        var updateOccurrencePatternCheckbox = this.get('updateOccurrencePattern');
        var updateOccurrencePattern = undefined;

        var hasRecurrence = !_ember['default'].isNone(recurrencePattern);

        // If checkbox not checked we still need to check if it was unchecked by the user or if there were no edited occurrences
        if (hasRecurrence && this.get('bookingMode') === 2) {
          updateOccurrencePattern = updateOccurrencePatternCheckbox;
        }

        var formData = null;
        if (this.get('model.bookingForm')) {
          formData = this._getFormValues();
        }

        this.get('bookingRequestUpdater').updateRequest({
          bookingRequestId: bookingRequestId,
          name: name,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          description: description,
          resources: resources,
          recurrencePattern: recurrencePattern,
          updateOccurrencePattern: updateOccurrencePattern,
          formData: formData

        }).then(function (updatedBooking) {
          _this5.get('store').unloadAll('booking-request');
          _this5.editSuccessFlash('booking-request');

          var currentUserEmail = _this5.get('currentUser.user.email');
          var bookerEmail = _this5.get('bookingRequest.bookerEmailAddress');
          _this5.get('appInsights').trackEvent('booking-edited', {
            type: 'booking',
            isOwner: currentUserEmail === bookerEmail,
            bookingId: bookingRequestId,
            resourceId: _this5.get('model.resource.id'),
            resourceName: _this5.get('model.resource.name'),
            bookingTypeId: _this5.get('model.bookingType.id'),
            bookingTypeName: _this5.get('model.bookingType.name'),
            from: _this5.get('editFromPage'),

            isRecurring: !_ember['default'].isEmpty(recurrencePattern),
            recurrenceType: recurrencePattern ? recurrencePattern.recurrenceType : null,
            startDate: startDateTime.format(),
            endDate: endDateTime.format(),
            duration: moment.duration(endDateTime.diff(startDateTime)).asMinutes()
          });

          _this5.set('editBookingRequest', null);

          var editFromPage = _this5.get('editFromPage');

          if (editFromPage === 'manage-resources') {
            _this5.transitionToRoute('manage-resources.resource.show', mainResource.get('resourceTypeId'), mainResource.get('id'), {
              queryParams: {
                formattedDisplayedDate: startDateTime.format(DATE_FORMAT),
                bookingId: updatedBooking.eventId
              }
            });
          } else if (editFromPage === 'concurrency-calendar') {
            _this5.transitionToRoute('manage-resources.resource-type.concurrency.show', _this5.get('concurrencyId'), {
              queryParams: {
                formattedDisplayedDate: startDateTime.format(DATE_FORMAT),
                bookingId: updatedBooking.eventId,
                isConfiguration: _this5.get('isConfiguration')
              }
            });
          } else if (editFromPage === 'report') {
            _this5.transitionToRoute('manage-resources.reports.index', { queryParams: {
                section: 0,
                resume: true,
                bookingId: updatedBooking.eventId }
            });
          } else if (editFromPage === 'unavailability') {
            _this5.send('leaveEditMode');
          } else {
            _this5.transitionToRoute('logged-in.booking-request', {
              queryParams: {
                formattedDisplayedDate: startDateTime.format(DATE_FORMAT),
                bookingId: updatedBooking.eventId,
                refreshCache: true
              }
            });
          }
        })['catch'](function (jqXHR) {
          return _this5._handleErrors(jqXHR, deferred, true);
        })['finally'](function () {
          _this5.set('isSavingForm', false);
          deferred.resolve();
        });
      },

      fetchEvents: function fetchEvents(start, end) {
        var _this6 = this;

        var model = this.get('model');
        this.send('loadingWithoutTransition');

        this.set('isShowingAvailability', false);

        var startDate = moment(start);
        var endDate = moment(end);

        var bookingTypeId = model.bookingType.get('id');
        var resourceId = model.resource.get('id');

        this.set('currentTimeSpanStart', start);
        this.set('currentTimeSpanEnd', end);

        var unavailabilityPromise = this._fetchUnavailability({
          resourceId: resourceId,
          bookingTypeId: bookingTypeId,
          startDate: startDate,
          endDate: endDate
        });

        var bookingsUnavailabilityPromise = this._fetchBookings({
          resourceId: resourceId,
          startDate: start,
          endDate: end
        });

        var promises = unavailabilityPromise.concat(bookingsUnavailabilityPromise);

        _ember['default'].RSVP.all(promises).then(function (events) {
          var allEvents = events.reduce(function (memo, events) {
            return memo.concat(events);
          }, []);

          // // Filter out duplicate template unavailability
          var filteredEvents = allEvents.reduce(function (memo, event) {
            var hasDupe = memo.filter(function (memoEvent) {
              return !event.isMasking && !memoEvent.isMasking && (event.tag === 'Template' || event.tag === 'General') && (memoEvent.tag === 'Template' || memoEvent.tag === 'General') && memoEvent.startDateTime.isSame(event.startDateTime) && memoEvent.endDateTime.isSame(event.endDateTime);
            });

            if (!hasDupe.length) memo.pushObject(event);
            return memo;
          }, []);

          model.events.clear();
          model.events.addObjects(filteredEvents);
        })['finally'](function () {
          _this6.send('finished');
        })['catch'](function () {
          // no-op
        });
      },

      searchResources: function searchResources(resourceRestriction, query, deferred) {
        if (_ember['default'].isEmpty(query.term)) {
          this.send('_searchResources', resourceRestriction, query, deferred);
        } else {
          _ember['default'].run.debounce(this, 'send', '_searchResources', resourceRestriction, query, deferred, 1000);
        }
      },

      _searchResources: function _searchResources(resourceRestriction, query, deferred) {
        var _this7 = this;

        if (!resourceRestriction) return false;

        var rrResources = this.get('resourcesSelected').findBy('id', resourceRestriction.id);

        if (rrResources && rrResources.get('values') && resourceRestriction.get('max') <= rrResources.get('values.length')) {
          deferred.reject(this.get('i18n').t('components.booking_request_form.limit_reached'));
        }

        this.get('additionalResourcesSearcher').search({
          resourceTypeId: resourceRestriction.get('resourceType.id'),
          resourceGroups: resourceRestriction.get('resourceGroups'),
          query: query.term,
          page: query.page,
          bookingTypeId: this.get('model.bookingType.id')
        }).then(function (data) {
          var resourcesSelected = _this7.get('resourcesSelected').getEach('values');
          var resourcesSelectedIds = [].concat.apply([], resourcesSelected).getEach('id');

          var resources = data.results.filter(function (x) {
            return !resourcesSelectedIds.includes(x.id) && x.id !== _this7.get('model.resource.id');
          });

          var results = {
            data: resources,
            more: data.totalPages > data.currentPage
          };
          deferred.resolve(results);
        });
      },

      verifyForDuplicates: function verifyForDuplicates(resource, deferred) {
        deferred.resolve(this.get('chosenResources').includes(resource));
      },

      didSelectResourceRestrictionResource: function didSelectResourceRestrictionResource(resourceRestriction, resources) {
        var resourcesSelected = this.get('resourcesSelected');
        var existingResourceRestriction = resourcesSelected.findBy('id', resourceRestriction.id);

        if (_ember['default'].isEmpty(existingResourceRestriction)) {
          resourcesSelected.pushObject(_ember['default'].Object.create({
            id: resourceRestriction.id,
            values: resources
          }));
        } else {

          // If the dropdown sent us no resources it means we have removed last one of the resources
          // of this restriction so just remove the whole empty object
          if (_ember['default'].isEmpty(resources)) {
            resourcesSelected.removeObject(existingResourceRestriction);
          } else {
            existingResourceRestriction.set('values', resources);
          }
        }
        this.send('fetchUnavailabilitySlots');
      },

      fetchUnavailabilitySlots: function fetchUnavailabilitySlots() {
        this.send('fetchEvents', this.get('currentTimeSpanStart'), this.get('currentTimeSpanEnd'));
      },

      searchUsers: function searchUsers(query, deferred) {
        var _this8 = this;

        if (query !== this.get('currentUserSearchQuery')) {
          this.setProperties({
            currentUserSearchQuery: query,
            currentUserSearchResultsPage: 1
          });
        }

        this.get('userSearcher').search({
          query: query.term,
          pageNumber: this.get('currentUserSearchResultsPage')
        }).then(function (data) {
          _this8.incrementProperty('currentUserSearchResultsPage');
          var hasMorePages = _this8.get('currentUserSearchResultsPage') === data.totalPages;

          data = data || [];
          deferred.resolve({
            data: data.results,
            more: hasMorePages
          });
        });
      },

      showAvailability: function showAvailability() {
        var calendar = _ember['default'].$('.chronos');
        calendar.find('span.chronos-weekView-hourGrid-item').css('background-color', this.get('isShowingAvailability') ? '#FFF' : '#3BBD95');
        this.toggleProperty('isShowingAvailability');

        this.get('appInsights').trackEvent('show-availability', {
          mainResource: JSON.stringify(this.get('model.resource').toJSON({
            includeId: true
          })),
          additionalResources: this.get('resourcesSelected.length')
        });
      },

      onEventClick: function onEventClick(event) {

        if (event.isMasking) return;

        var ev = this.get('model.events').findBy('id', event.id);
        if (!ev) return;

        if (ev.constructor.modelName === 'booking-request') {

          // If in edit mode do not allow to view another booking request
          if (this.get('isEditing')) return this.flashInfo(this.get('i18n').t('pods.logged_in.resource.show.event_click_in_edit_mode'));

          this.send('openBookingRequestOverview', null, ev);
        } else if (ev.availabilityType === 2) {
          this.send('showEventOverview', ev);
        }
      },

      showEventOverview: function showEventOverview(event) {
        var _this9 = this;

        this.send('loadingWithoutTransition');
        this.get('unavailabilityEventFetcher').fetch(this.get('model.resource.resourceTypeId'), this.get('model.resource.id'), event.eventId, event.startDateTime, event.endDateTime).then(function (ev) {
          _this9.set('showEventOverview', ev[0]);
        })['catch'](function () {
          // no-op
        })['finally'](function () {
          _this9.send('finished');
        });
      },

      openBookingRequestOverview: function openBookingRequestOverview(eventId, chronosEvent) {
        var _this10 = this;

        var bookingPromise = undefined;
        if (chronosEvent.hasRecurrence) {
          var bookingObj = this.get('store').peekAll('booking-request').findBy('id', chronosEvent.id);
          var id = bookingObj.get('eventId');
          bookingPromise = this.get('store').queryRecord('booking-request', { bookingId: id, occurrenceId: chronosEvent.id });
        } else {
          bookingPromise = this.get('store').findRecord('booking-request', chronosEvent.id);
        }

        bookingPromise.then(function (bookingRequest) {
          _this10.set('showBookingRequest', bookingRequest);
          _this10.set('model.bookingRequest', bookingRequest);
        });
      },

      closeBookingOverview: function closeBookingOverview() {
        this.set('showBookingRequest', null);
      },

      closeInvalidBookingTimesModal: function closeInvalidBookingTimesModal() {
        this.set('showInvalidBookingTimesModal', false);
        this._highlightAdjustedTimeInputs();
      },

      onViewTypeChanged: function onViewTypeChanged(viewType) {
        this.set('calendarViewType', viewType);
      },

      onBookingTypeHelpTriggerClick: function onBookingTypeHelpTriggerClick() {
        this.get('appInsights').trackEvent('template-help-click', {
          bookingTypeName: this.get('model.bookingType.name'),
          bookingTypeId: this.get('model.bookingType.id'),
          isResourceGroup: this.get('isResourceGroup'),
          resourceGroupId: this.get('resourceGroupId'),
          resourceGroupName: this.get('model.resourceGroup') ? this.get('model.resourceGroup.name') : ''
        });
        this.set('showBookingTypeHelpMessageModal', true);
      },

      leaveEditMode: function leaveEditMode() {

        var booking = this.get('model.bookingRequest');

        if (booking) {
          booking.rollbackAttributes();
        }

        var editFromPage = this.get('editFromPage');

        if (editFromPage === 'manage-resources') {
          var queryParams = {
            date: booking.get('startDateTime').format(DATE_FORMAT),
            bookingId: booking.get('eventId')
          };

          if (this.get('occurrenceId')) queryParams.occurrenceId = this.get('occurrenceId');

          this.transitionToRoute('manage-resources.resource.show', this.get('model.resource.resourceTypeId'), this.get('model.resource.id'), {
            queryParams: queryParams
          });
        } else if (editFromPage === 'concurrency-calendar') {
          this.transitionToRoute('manage-resources.resource-type.concurrency.show', this.get('concurrencyId'), {
            queryParams: {
              date: booking.get('startDateTime').format(DATE_FORMAT),
              bookingId: booking.get('eventId'),
              isConfiguration: this.get('isConfiguration'),
              lanesToggled: this.get('editCalendarViewType') === 'master'
            }
          });
        } else if (editFromPage === 'report') {

          var queryParams = {
            section: 0,
            resume: true,
            bookingId: booking.get('eventId')
          };

          if (this.get('occurrenceId')) queryParams.occurrenceId = this.get('occurrenceId');

          this.transitionToRoute('manage-resources.reports.index', { queryParams: queryParams });
        } else if (editFromPage === 'unavailability') {
          this.setProperties({
            bookingId: '',
            bookingMode: 1,
            occurrenceId: '',
            recurrencePattern: null,
            showBookingRequest: null,
            showBookingRequestForm: false,
            bookingRequest: null,
            'model.bookingRequest': null,
            'editFromPage': null
          });

          this.send('fetchEvents', this.get('currentTimeSpanStart'), this.get('currentTimeSpanEnd'));
        } else if (editFromPage === 'my-bookings') {
          var queryParams = {
            date: this.get('model.bookingRequest.startDateTime').format(DATE_FORMAT),
            bookingId: this.get('bookingId')
          };

          if (this.get('occurrenceId')) queryParams.occurrenceId = this.get('occurrenceId');
          this.transitionToRoute('logged-in.booking-request.index', {
            queryParams: queryParams
          });
        }
      },

      transitionToBookingType: function transitionToBookingType() {
        this.transitionToRoute('logged-in.booking-type.show', this.get('model.bookingType.id'));
      }

    },

    _handleErrors: function _handleErrors(jqXHR, deferred, requestData) {
      var isUpdate = arguments.length <= 3 || arguments[3] === undefined ? false : arguments[3];

      if (jqXHR && jqXHR.responseJSON) {
        var messageData = jqXHR.responseJSON;

        var errorMessage = this.get('bookingRequestErrorHandler').handle(jqXHR);

        this.set('formError', errorMessage || messageData);
        _ember['default'].$('.lf-dialog-content').animate({
          scrollTop: 0
        }, 'slow');
      } else {
        if (isUpdate) {
          this.editErrorFlash('booking-request');
        } else {
          this.createErrorFlash('booking-request');
        }

        LE.error({
          error: jqXHR,
          request: requestData
        });
      }

      deferred.reject();
    },

    _getFormValues: function _getFormValues() {

      var bookingForm = this.get('model.bookingForm');
      var sectionsData = bookingForm.get('sections').reduce(function (memo, s) {

        var sectionData = {
          identity: s.get('identity'),
          name: s.get('name'),
          rank: s.get('rank')
        };

        sectionData.fields = s.get('fields').reduce(function (fieldsMemo, field) {
          // We still want to send the URL and Label fields even if it is read only
          if (!field.get('isActive') || field.get('isReadOnlyField') && field.get('fieldType') !== 6 && field.get('fieldType') !== 1) return fieldsMemo;

          var fieldValue = undefined;
          var fieldType = field.get('fieldType');
          // field.get('fieldType') === 2 ? field.get('value') : [field.get('value')]
          if (fieldType === 2) {
            fieldValue = field.get('value');
            // } else if (fieldType === 4 && field.get('isRadio')) {
            //   let item = field.get('items').findBy('value', field.get('value'));
            //   fieldValue = [{text: item.get('text'), value: item.get('value')}];
          } else if (fieldType === 6) {
              fieldValue = [field.get('url'), field.get('text')];
            } else if (fieldType === 1) {
              fieldValue = [field.get('description')];
            } else {
              fieldValue = [field.get('value')];
            }

          fieldsMemo.pushObject({
            identity: field.get('identity'),
            rank: field.get('rank'),
            description: field.get('description'),
            fieldType: fieldType,
            shortDescription: field.get('shortDescription'),
            values: fieldValue
          });
          return fieldsMemo;
        }, []);

        if (!_ember['default'].isEmpty(sectionData.fields)) {
          memo.pushObject(sectionData);
        }

        return memo;
      }, []);

      return {
        id: bookingForm.get('id'),
        name: bookingForm.get('name'),
        sections: sectionsData
      };
    },

    _createResourcesObject: function _createResourcesObject() {

      var mainResource = this.get('model.resource').toJSON({
        includeId: true
      });

      var rules = this.get('model.bookingType.resourceRestrictionRules');
      var primaryResourceRestrictionId = rules.findBy('isPrimary').get('id');

      _ember['default'].set(mainResource, 'resourceRestrictionId', primaryResourceRestrictionId);
      _ember['default'].set(mainResource, 'isPrimary', true);

      var resources = [];
      resources.unshift(mainResource);

      var resourcesSelected = this.get('resourcesSelected');

      resourcesSelected.forEach(function (restriction) {
        var resourcesForRestriction = restriction.get('values');
        var resourcesWithRestrictionId = resourcesForRestriction.map(function (resource) {
          try {
            resource = resource.toJSON({
              includeId: true
            });
          } catch (err) {
            // no-op
          }

          _ember['default'].set(resource, 'resourceRestrictionId', _ember['default'].get(restriction, 'id'));
          return resource;
        });

        resources.pushObjects(resourcesWithRestrictionId);
      });

      var restrictionIds = this.get('model.bookingType.resourceRestrictionRules').getEach('id');
      return resources.filter(function (r) {
        return restrictionIds.includes(_ember['default'].get(r, 'resourceRestrictionId'));
      });
    },

    _setEditedResourcesOnLoad: function _setEditedResourcesOnLoad() {
      var bookingToEdit = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
      var bookingRefittedResources = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
      var editRestrictions = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

      var booking = bookingToEdit || this.get('model.bookingRequest');

      var bookingResources = booking.get('resources');

      var brrId = bookingResources.get('firstObject.resourceRestrictionId');
      var resourcesHaveRestrictionId = brrId && brrId !== '00000000-0000-0000-0000-000000000000';

      var primaryResource = this.get('model.resource');

      var refittedResources = bookingRefittedResources || this.get('model.bookingRequest.refittedResources');

      var modelEditRestrictions = editRestrictions ? editRestrictions : this.get('model.bookingRequest.editRestrictions') || [];
      modelEditRestrictions = modelEditRestrictions.rejectBy('messageKey', 'RR_MaxLimitChanged');

      var affectedResources = modelEditRestrictions.getEach('affectedResources') || [];

      var invalidResourceIds = affectedResources.reduce(function (memo, restriction) {

        // There might not be affectedResources so we need to check for null
        if (!restriction) return memo;

        memo.pushObjects(restriction.getEach('id'));
        return memo;
      }, []);

      var resources = resourcesHaveRestrictionId ? bookingResources : refittedResources;

      var bookingTypeRestrictions = this.get('model.bookingType.resourceRestrictionRules');

      var resourcesSelected = resources.reduce(function (memo, resource) {

        // Ignore if resource id is in editRestrictions (which means resource was deleted)
        if (invalidResourceIds.includes(resource.get('id'))) return memo;
        // We want to ignore the main resource id
        if (resource.get('id') === primaryResource.get('id')) return memo;

        var restriction = bookingTypeRestrictions.findBy('id', resource.get('resourceRestrictionId'));

        // Ignore resource if no restriction for it is found on the booking type
        if (_ember['default'].isEmpty(restriction)) {
          return memo;
        }

        // ignore resource if its resourceTypeId doesn't match the one of the booking type restriction
        if (resource.get('resourceTypeId') !== restriction.get('resourceType.id')) {
          return memo;
        }

        var resourceRestrictionId = resource.get('resourceRestrictionId');
        var idExists = !_ember['default'].isEmpty(memo.findBy('id', resourceRestrictionId));

        if (!idExists) {
          memo.pushObject(_ember['default'].Object.create({
            id: resourceRestrictionId,
            values: []
          }));
        }
        memo.findBy('id', resourceRestrictionId).get('values').pushObject(resource);
        return memo;
      }, []);

      this.set('resourcesSelected', resourcesSelected);
    },

    _setBookingFormEditedValues: function _setBookingFormEditedValues() {
      var form = this.get('model.bookingForm');
      var bookingRequest = this.get('model.bookingRequest');
      var bookingSections = bookingRequest.get('bookingRequestFormValue.sections');
      var formSections = form.get('sections');

      bookingSections.forEach(function (bookingSection) {
        var formSectionExists = formSections.findBy('identity', bookingSection.identity);
        if (formSectionExists) {
          (function () {
            var formFields = formSectionExists.get('fields');
            var bookingFields = bookingSection.fields || [];
            bookingFields.forEach(function (bookingField) {
              var formFieldExists = formFields.findBy('identity', bookingField.identity);
              if (formFieldExists) {
                if (bookingField.fieldType !== 2 && bookingField.values) {
                  formFieldExists.set('value', bookingField.values[0]);
                } else if (bookingField.fieldType === 2 && bookingField.values) {
                  formFieldExists.set('value', bookingField.values);
                }
              }
            });
          })();
        }
      });
    },

    _fetchBookings: function _fetchBookings(_ref5) {
      var _this11 = this;

      var resourceId = _ref5.resourceId;
      var startDate = _ref5.startDate;
      var endDate = _ref5.endDate;

      // Below we fetch bookings to show as unavailability slots. If we are currently editing a booking
      // we need to make sure that below call doesn't refetch that booking as it would override
      // data already changed on the booking form, therefore we pass in the ignoreId/ignoreEventId to the service
      // which will filter out any objects with those Id/EventId's
      var bookingId = this.get('bookingId');
      var occurrenceId = this.get('occurrenceId');
      var eventIdToIgnore = !occurrenceId ? bookingId : null;
      var idToIgnore = occurrenceId;

      var modelEvents = this.get('resourceBookingRequestsFetcher').fetch({
        resourceId: resourceId,
        startDate: startDate,
        endDate: endDate,
        checkSplitPermissions: true,
        ignoreId: idToIgnore,
        ignoreEventId: eventIdToIgnore
      }).then(this._fetchBookingsProcessResults.bind(this));

      var resourcesSelected = this.get('resourcesSelected').getEach('values');
      var allResources = [].concat.apply([], resourcesSelected);

      var promises = allResources.map(function (resource) {
        return _this11.get('resourceBookingRequestsFetcher').fetch({
          resourceId: _ember['default'].get(resource, 'id'),
          startDate: startDate,
          endDate: endDate
        }).then(_this11._fetchBookingsProcessResults.bind(_this11));
      });

      promises.unshift(modelEvents);
      return promises;
    },

    _fetchBookingsProcessResults: function _fetchBookingsProcessResults(bookings) {
      var _this12 = this;

      bookings = bookings.map(function (booking) {
        booking.setProperties({
          tag: _this12.get('UNAVAILABILITY_TYPES')[3].capitalize(),
          extraClassNames: 'chronos-event--' + booking.get('status').toLowerCase()
        });
        return booking;
      });

      if (this.get('isEditing')) {
        bookings = bookings.reject(function (b) {
          return b.get('id') === _this12.get('model.bookingRequest.id');
        });

        if (this.get('occurrenceId')) {
          bookings = bookings.rejectBy('id', this.get('occurrenceId'));
        } else {
          bookings = bookings.reject(function (b) {
            return b.get('eventId') === _this12.get('model.bookingRequest.eventId');
          });
        }
      }

      // We also need to create masking for the bookings
      var isEditing = this.get('isEditing');
      var isOccurrence = this.get('occurrenceId');
      var hasOccurrences = !_ember['default'].isEmpty(this.get('model.bookingRequest.occurrences'));

      bookings.forEach(function (booking) {
        var slotMasking = _ember['default'].Object.create({
          id: booking.id + '-masking',
          isMasking: true,
          startDateTime: booking.get('startDateTime'),
          endDateTime: booking.get('endDateTime')
        });

        // If we are editing a normal booking, an occurrence, or a recurrence booking
        // we might not want to add the masking so that the user can stil click on the same slot to edit the booking
        var shouldAddMasking = true;
        if (isEditing) {
          // If we are editing an occurrence we don't want to show the masking for that occurrence on the calendar
          if (isOccurrence && booking.id === _this12.get('model.bookingRequest.id')) {
            shouldAddMasking = false;
          } else if (hasOccurrences) {
            // If we are editing a recurring booking we don't want to show any masking for its occurrences.
            var occurrenceIds = _this12.get('model.bookingRequest.occurrences').getEach('id');
            shouldAddMasking = occurrenceIds.includes(booking.id);
          }
        }

        if (shouldAddMasking) bookings.pushObject(slotMasking);
      });

      return bookings;
    },

    _fetchUnavailability: function _fetchUnavailability(_ref6) {
      var _this13 = this;

      var resourceId = _ref6.resourceId;
      var bookingTypeId = _ref6.bookingTypeId;
      var startDate = _ref6.startDate;
      var endDate = _ref6.endDate;

      var modelEvents = this.get('resourceEventsFetcher').fetch({
        resourceId: resourceId,
        bookingTypeId: bookingTypeId,
        startDate: startDate,
        endDate: endDate
      }).then(this._fetchUnavailabilityProcessResults.bind(this));

      var resourcesSelected = this.get('resourcesSelected').getEach('values');
      var allResources = [].concat.apply([], resourcesSelected);

      var promises = allResources.map(function (resource) {
        return _this13.get('resourceEventsFetcher').fetch({
          resourceId: _ember['default'].get(resource, 'id'),
          bookingTypeId: bookingTypeId,
          startDate: startDate,
          endDate: endDate
        }).then(_this13._fetchUnavailabilityProcessResults.bind(_this13));
      });

      promises.unshift(modelEvents);
      return promises;
    },

    _fetchUnavailabilityProcessResults: function _fetchUnavailabilityProcessResults(results) {
      var _this14 = this;

      var data = results.filter(function (event) {

        return event.availabilityType !== 3;
      }).reduce(function (memo, event) {

        var isPattern = event.repeats;

        var startDateTime = undefined;

        // If it's a pattern, we need to keep the date intact to match the correct day of the week)
        // but adjust the time to local
        if (isPattern) {
          var localStart = moment(event.startDateTime);

          var startDateTimeSplit = event.startDateTime.split('-');
          var year = startDateTimeSplit[0];
          var month = parseInt(startDateTimeSplit[1]) - 1;
          var date = startDateTimeSplit[2].split('T');
          localStart.year(year).month(month).date(date[0]);
          startDateTime = localStart;
        } else {
          startDateTime = moment(event.startDateTime);
        }

        var endDateTime = startDateTime.clone().add(event.duration, 'minutes');

        var slot = _ember['default'].Object.create({
          id: event.id,
          eventId: event.eventId,
          tag: _this14.get('UNAVAILABILITY_TYPES')[event.availabilityType].capitalize(),
          name: event.availabilityType === 5 ? event.concurrencyResourceName : event.name || null,
          availabilityType: event.availabilityType,
          extraClassNames: 'chronos-unavailability ' + (isPattern ? 'chronos-unavailability-pattern' : ''),
          startDateTime: startDateTime,
          endDateTime: endDateTime
        });

        var slotMasking = _ember['default'].Object.create({
          id: event.id + '-masking',
          isMasking: true,
          startDateTime: startDateTime,
          endDateTime: endDateTime
        });

        memo.pushObjects([slot, slotMasking]);
        return memo;
      }, []);

      return data;
    },

    /**
     * Highlights/Flashes red the time inputs on the booking form popup due to start time and end time not
     * falling on the hour (xx:00 - yy:00) indicating to the user the times might need adjusting
     */
    _highlightAdjustedTimeInputs: function _highlightAdjustedTimeInputs() {
      var highlightDisabled = this.get('systemSettings').getSetting('calendarDisableWarnOnHoursNotAligned');
      if (highlightDisabled) return;
      _ember['default'].run.later(this, function () {
        _ember['default'].$('.timeInputSelect.at-bookingForm').addClass('timeInputSelect--invalid');
        _ember['default'].$('.bookingRequestForm-input.date-input').addClass('date-input--invalid');
      }, 150);

      if (this.get('isEditing')) {
        this.set('showAdjustedTimesBanner', true);
      }
    },

    breadCrumbs: _ember['default'].computed('model.bookingType.name', 'model.resource.name', 'bookingTypeShowController.resourceGroupId', 'i18n.locale', function () {

      var bookingTypeShowController = this.get('bookingTypeShowController');

      // Booking Type Index and Booking type breadcrumbs
      var breadcrumbs = [{
        label: this.get('i18n').t('pods.logged_in.resource.show.bookings'),
        path: 'logged-in.booking-type.index'
      }, {
        label: this.get('model.bookingType.name'),
        path: 'logged-in.booking-type.show',
        model: this.get('model.bookingType.id')
      }];

      if (bookingTypeShowController.get('resourceGroupId')) {
        breadcrumbs.pushObject({
          label: this.get('bookingTypeShowController.model.resourceGroup.name'),
          path: 'logged-in.booking-type.show',
          model: this.get('model.bookingType.id')
        });
      }

      breadcrumbs.pushObject({
        label: this.get('model.resource.name'),
        linkable: false
      });

      return breadcrumbs;
    })

  });
});