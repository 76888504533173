define('scientia-resourcebooker/pods/components/notifications-list/item/component', ['exports', 'ember'], function (exports, _ember) {

  var supportedIcons = {
    Requested: 'requested',
    Rejected: 'rejected',
    Accepted: 'accepted',
    Cancelled: 'cancelled',
    Changed: 'changed',
    MoreInfoRequested: 'moreinforequested'
  };

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['notificationsList-item'],
    attributeBindings: ['data-notificationId'],
    tagName: 'li',
    currentUser: _ember['default'].inject.service(),
    notification: null,
    'data-notificationId': _ember['default'].computed.alias('notification.eventId'),
    loadingBookingRequest: false,
    isSaving: false,
    isStatusRequestMoreInfo: _ember['default'].computed.equal('notification.notificationType', 'MoreInfoRequested'),

    bookerEmailAddress: _ember['default'].computed.alias('notification.bookerEmailAddress'),
    currentUserEmailAddress: _ember['default'].computed.alias('currentUser.user.email'),
    notificationBookedBySelf: _ember['default'].computed('bookerEmailAddress', 'currentUserEmailAddress', function () {
      return this.get('bookerEmailAddress') === this.get('currentUserEmailAddress');
    }),

    notes: _ember['default'].computed.alias('notification.notes'),

    isRecurring: _ember['default'].computed.gt('notification.schedule.length', 1),

    description: (function () {

      // Get schedule information. (At the moment we are using the 1st Object of the array as only one is being retrieved)
      var schedule = this.get('notification').schedule.objectAt(0);
      var fromDate = moment(schedule.startDateTime);
      var toDate = fromDate.clone().add(schedule.duration, 'minutes');

      var notificationType = this.get('notification.notificationType').toLowerCase();
      var localePath = 'components.notification';

      var tensePath = '';
      if (notificationType === 'moreinforequested') {
        tensePath = 'descriptions.tense_with_status.more_info';
      } else if (notificationType === 'requested') {
        tensePath = 'descriptions.tense_with_status.has_been';
      } else {
        tensePath = 'descriptions.tense_with_status.was';
      }

      var fullTensePath = localePath + '.' + tensePath;

      // Check if we should use the "self" strings or third_party strings from the translation file.
      var localeSubPath = this.get('notificationBookedBySelf') ? 'descriptions.self.' : 'descriptions.third_party.';

      localeSubPath += 'description';

      var spanMultipleDays = !fromDate.isSame(toDate.clone().subtract(1, 'minute'), 'day');

      var endTimeMidnightAsTwentyFour = toDate.hour() === 0 && toDate.minute() === 0;

      // Use different translation if span multiple days to include full date time formatting
      if (spanMultipleDays) {
        localeSubPath += '_span_multiple_days';

        // T handle new 24h as midnight we need to subtract 1 day from toDate
        if (endTimeMidnightAsTwentyFour) toDate.subtract(1, 'day');
      }

      var notificationStringPath = localePath + '.' + localeSubPath;
      var actionedPath = 'descriptions.actioned.actioned_by';
      var fullActionedPath = localePath + '.' + actionedPath;

      var actionedString = '';
      var actionedBy = this.get('notification').actionedBy;
      var dayFormat = this.get('i18n').t(localePath + '.day_format').toString();

      if (actionedBy !== null && notificationType !== 'cancelled') {
        var actionedDate = moment(this.get('notification').actionedAt);
        actionedString = this.get('i18n').t(fullActionedPath, {
          actionedBy: actionedBy,
          actionedDate: actionedDate.format(dayFormat)
        });
      }

      var hourFormat = this.get('i18n').t(localePath + '.hour_format').toString();

      var resourceName = this.get('notification').resourceName;

      var notificationStatus = this.get('i18n').t(localePath + '.types.' + notificationType);

      var tenseString = this.get('i18n').t(fullTensePath, {
        status: notificationStatus,
        actionedInfo: actionedString
      });

      return this.get('i18n').t(notificationStringPath, {
        day: fromDate.format(dayFormat),
        from: fromDate.format(hourFormat),
        to: endTimeMidnightAsTwentyFour ? this.get('i18n').t('date_formats.midnight_twenty_four').toString() : toDate.format(hourFormat),
        tenseWithStatus: tenseString,
        startDate: spanMultipleDays ? fromDate.format(dayFormat) : null,
        endDate: spanMultipleDays ? toDate.format(dayFormat) : null,
        resource: resourceName
      });
    }).property('notification.notificationType'),

    icon: (function () {
      return supportedIcons[this.get('notification.notificationType')] || 'requested';
    }).property('notification.notificationType'),

    notificationTimeFormat: (function () {
      var createdLabel = this.get('i18n').t('components.notification.created').toString();
      var timeAgo = moment(this.get('notification.createdAt')).fromNow();
      var fullDate = moment(this.get('notification.createdAt')).format(this.get('i18n').t('components.notification.date_format').toString());
      return createdLabel + ' ' + timeAgo + ' (' + fullDate + ')';
    }).property('notification.createdAt'),

    notificationUpdatedAt: (function () {

      var actionedAt = this.get('notification.actionedAt');

      // Don't create Update label if no updateAt value or if updateAt is same as createdAt value
      if (!actionedAt || actionedAt.indexOf('0001-01-01') === 0 || actionedAt === this.get('notification.createdAt')) return null;

      var updatedLabel = this.get('i18n').t('components.notification.updated').toString();
      var timeAgo = moment(this.get('notification.actionedAt')).fromNow();
      var fullDate = moment(this.get('notification.actionedAt')).format(this.get('i18n').t('components.notification.date_format').toString());
      return updatedLabel + ' ' + timeAgo + ' (' + fullDate + ')';
    }).property('notification.actionedAt', 'notification.createdAt'),

    isLoadingBookingRequest: (function () {
      return this.get('loadingBookingRequest') === this.get('notification.eventId');
    }).property('loadingBookingRequest', 'notification.eventId'),

    actions: {
      openEventOverview: function openEventOverview(request) {
        this.sendAction('openEventOverview', request);
      },

      accept: function accept(request) {
        // this.set('isSaving', true);
        this.sendAction('accept', request);
      },

      reject: function reject(request) {
        this.sendAction('reject', request);
      },

      moreInfo: function moreInfo(request) {
        if (!this.get('isStatusRequestMoreInfo')) {
          this.sendAction('moreInfo', request);
        }
      },

      viewNotes: function viewNotes(request) {
        this.sendAction('viewNotes', request);
      }
    }
  });
});